import { AxiosResponse } from "axios";
import axios from "axios";
import { GetServiceUrl } from "../../../helpers/configManager";
import { clearAuthTokens, getAccessToken, getRefreshToken, getTenant, setAuthTokens } from "./axios";

// 1. Create an axios instance that you wish to apply the interceptor to
const axiosRefreshTokenInstance = axios.create({
    baseURL: GetServiceUrl()
});

export const shouldRefreshToken = async (response: AxiosResponse) => {
    const errors = response?.data?.StatusCode;
    if (errors != 401) return false;

    const currentRefreshToken = await getRefreshToken();
    if (!currentRefreshToken) return false;

    return true;
};

export const refreshTokens = async () => {
    const refreshToken = await getRefreshToken();
    if (!refreshToken) {
        console.log("Refresh Token bulunamadı");
        return;
    }

    const token = await getAccessToken();
    if (!token) {
        console.log("Access Token bulunamadı");
        return;
    }


    try {
        const response = await axiosRefreshTokenInstance.post(`${GetServiceUrl()}/token/refresh`, { token, refreshToken }, {
            headers: {
                'tenant': await getTenant()
            }
        })

        var authTokens = {
            accessToken: response.data.token as string,
            refreshToken: response.data.refreshToken as string
        };
        await setAuthTokens(authTokens);
        return authTokens;

    } catch (error) {
        clearAuthTokens();
        throw error;
    }
};