import { StorageProxy } from "../providers/data-provider/utils/StorageProxy";

export class CacheManager {
  static async setItem<T>(key: string, value: T, ttl: number = 3600) {
    const now = new Date();
    // Veri ile birlikte, verinin ne zaman sona ereceğini de saklıyoruz
    const item = {
      value: value,
      expiry: now.getTime() + ttl * 1000,
    };
    await StorageProxy.Storage?.set(key, JSON.stringify(item));
  }

  // Veriyi Storage'dan çekme
  static async getItem<T>(key: string): Promise<T | null> {
    const itemStr = await StorageProxy.Storage?.get(key);
    // Eğer item yoksa, null dön
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Verinin süresi dolmuşsa, null dön ve Storage'dan bu veriyi kaldır
    if (now.getTime() > item.expiry) {
      await await StorageProxy.Storage?.remove(key);
      return null;
    }

    return item.value;
  }

  // Bir key'e bağlı veriyi Storage'dan silme
  static async removeItem(key: string) {
    await StorageProxy.Storage?.remove(key);
  }

  // Tüm Storage verilerini silme
  static async clear() {
    await StorageProxy.Storage?.clear();
  }
}
