import { ConditionalFilter, CrudFilter, CrudFilters, LogicalFilter } from "@refinedev/core";
import { mapOperator } from "./mapOperator";
import { IBaseFilter } from "./interface/IBaseFilter";
import { IFilter } from "./interface/IFilter";

const processFilter = (filter: LogicalFilter | ConditionalFilter): IFilter => {
  if ("field" in filter) {
    const { field, operator, value } = filter;
    const mappedOperator = mapOperator(operator);

    return {
      field,
      operator: mappedOperator,
      value,
    };
  } else {
    const { operator, value } = filter;
    let filtersArray: IFilter[] = [];

    if (Array.isArray(value)) {
      filtersArray = value.map(subFilter => processFilter(subFilter));
    }
    else {
      filtersArray = [{
        field: undefined,
        operator: undefined,
        value: value,
        logic: undefined
      }];
    }

    return {
      logic: operator,
      filters: filtersArray
    };
  }
};

export const generateFilter = (filters?: CrudFilters, initialLogic: "and" | "or" = "and"): IBaseFilter => {
  let result: IBaseFilter = {
    keyword: undefined,
    advancedSearch: undefined,
    advancedFilter: { logic: initialLogic, filters: [] }
  };
  
  if (filters && filters.length > 0) {
    result.advancedFilter!.filters = filters.map(filter => processFilter(filter));
  }

  return result;
};