import { AuthProvider } from "@refinedev/core";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { axiosInstance } from "./data-provider/utils";
import { ITokenResponse } from "../model/visa/tokens/ITokenResponse";
import { IUserResponse } from "../model/visa/user/IUserResponse";
import { clearAuthTokens, getAccessToken, isLoggedIn, setAuthTokens, setTenant } from "./data-provider/utils/axios";
import { IServiceResponse } from "../model/IServiceResponse";
import { CacheManager } from "../helpers/cacheManager";

import * as Sentry from "@sentry/react";

declare module 'axios' {
  export interface AxiosRequestConfig {
    skipAuthRefresh?: boolean;
  }
}

export const authProvider: AuthProvider = {
  login: async ({ identifier, password }) => {
    if (identifier && password) {

      setTenant("root");

      try {
        const requestConfig: AxiosRequestConfig<any> = {
          skipAuthRefresh: true
        };

        const tokenResponse = await axiosInstance.post<IServiceResponse<ITokenResponse>>("token/create", {
          identifier,
          password
        }, requestConfig);

        // save tokens to storage
        await setAuthTokens({
          accessToken: tokenResponse.data.data.token,
          refreshToken: tokenResponse.data.data.refreshToken
        })


        return {
          success: true,
          redirectTo: "/",
        };
      }
      catch (err: any) {
        return {
          success: false,
          error: {
            name: "LoginError",
            message: err.message,
          },
        };
      }
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid username or password",
      },
    };
  },
  logout: async () => {
    await clearAuthTokens();
    await CacheManager.clear();

    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    var accessToken = new URL(document.location.toString()).searchParams.get("accessToken");
    var refreshToken = new URL(document.location.toString()).searchParams.get("refreshToken");
    if (accessToken && refreshToken) {
      //redirect ile gelinmiş, logout yap.
      await clearAuthTokens();
      await CacheManager.clear();

      return {
        authenticated: false,
        redirectTo: "/login?accessToken=" + accessToken + "&refreshToken=" + refreshToken,
      };
    }
    else {
      var isAuthenticated = await isLoggedIn();

      if (isAuthenticated) {
        const requestConfig: AxiosRequestConfig<any> = {
          skipAuthRefresh: true
        };

        const accessToken = await getAccessToken();
        const tokenValidateResponse = await axiosInstance.get<IServiceResponse<boolean>>("token/validate?token=" + accessToken, requestConfig);

        isAuthenticated = tokenValidateResponse.data.data;
      }

      if (isAuthenticated) {
        return {
          authenticated: true,
        };
      }

      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }
  },
  getIdentity: async () => {
    const identityResponse = await axiosInstance.get<IServiceResponse<IUserResponse>>("User/getIdentity");

    Sentry.setUser({ email: identityResponse?.data?.data?.email ?? "anonymous" });

    return identityResponse.data.data;
  },
  onError: async (error) => {
    console.error(error);
    var isAuthenticated = await isLoggedIn();

    if (isAuthenticated) {
      const requestConfig: AxiosRequestConfig<any> = {
        skipAuthRefresh: true
      };

      const accessToken = await getAccessToken();
      const tokenValidateResponse = await axiosInstance.get<IServiceResponse<boolean>>("token/validate?token=" + accessToken, requestConfig);

      isAuthenticated = tokenValidateResponse.data.data;
    }

    if (isAuthenticated) {
      if (error.response.status == 403)
        return { error, logout: !isAuthenticated, redirectTo: '/forbidden' };
      else
        return { error: error, logout: !isAuthenticated };
    }
    else {
      return { error: new Error("Lütfen tekrar giriş yapınız."), logout: !isAuthenticated, redirectTo: '/login' };
    }
  }
};