import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useDataGrid,
} from "@refinedev/mui";
import React from "react";
import { IRoleResponse } from "../../../model/visa/role/IRoleResponse";
import { useTranslation } from "@refinedev/core";

export const RoleList = () => {
  const { dataGridProps } = useDataGrid({});

  const { translate } = useTranslation();
  const columns = React.useMemo<GridColDef<IRoleResponse>[]>(
    () => [
      {
        field: "name",
        flex: 1,
        headerName: translate("pages.visa.role.fields.name"),
        minWidth: 200,
      },
      {
        field: "normalizedName",
        flex: 1,
        headerName: translate("pages.visa.role.fields.normalizedName"),
        minWidth: 200,
      },
      {
        field: "description",
        flex: 1,
        headerName: translate("pages.visa.role.fields.description"),
        minWidth: 200,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
