import { Box, TextField } from "@mui/material";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { Rbac } from "../../../components/pages/visa";
import { PermissionModule, RolePermissions } from "../../../components/pages/visa/permission/types";
import { FieldValues } from "react-hook-form";
import { useState } from "react";


export const RoleCreate = () => {
    const {
        saveButtonProps,
        refineCore: { formLoading, onFinish },
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<any>({
    });

    const [currentClaims, setCurrentClaims] = useState<PermissionModule[]>([]);


    const onPermissionChange = (activePerms: Array<string>) => {
        var newPerms = activePerms.map((value: string) => {
            let perm: PermissionModule = {
                controller: value.split('.')[0],
                permissionType: value.split('.')[1] as 'List' | 'Show' | 'Create' | 'Edit' | 'Delete'
            }

            return perm;
        });

        setCurrentClaims(newPerms);
    }

    const onFinishHandler = (data: FieldValues) => {

        onFinish({
            ...data,
            claims: currentClaims
        });
    };

    return (
        <Create isLoading={formLoading} saveButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(onFinishHandler)
        }}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={"Name"}
                    name="name"
                />
                <TextField
                    {...register("description", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={"Description"}
                    name="description"
                />

                <Rbac
                    onChange={onPermissionChange}
                    rows={currentClaims}
                    initialRows={{ permissions: [] }}
                />

            </Box>
        </Create>
    );
};
