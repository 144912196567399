import Config from "../config.json";

export function GetServiceUrl() {
    if (Config.IsDevelopment) {
        return Config.serviceLocalUrl + Config.apiVersion;
    }

    return Config.serviceUrl + Config.apiVersion;
}

export function GetCdnUrl() {
    return Config.cdnUrl;
}