import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { I18nProvider } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import {
  RefineSnackbarProvider,
  useNotificationProvider,
} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppIcon } from "./components/app-icon";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { ThemedLayoutV2 } from "./components/layout";
import { ThemedHeaderV2 } from "./components/layout/header";
import { ThemedSiderV2 } from "./components/layout/sider";
import { ThemedTitleV2 } from "./components/layout/title";
import './extensions/arrayExtensions';


import Dashboard from "@mui/icons-material/Dashboard";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import MapIcon from '@mui/icons-material/Map';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';


import { ForgotPassword } from "./pages/visa/forgotPassword";
import { Login } from "./pages/visa/login";
import { Register } from "./pages/visa/register";
import { GetServiceUrl } from "./helpers/configManager";
import { dataProvider } from "./providers/data-provider";
import { authProvider } from "./providers/authProvider";
import { ErrorComponent } from "./components/pages/error";
import { RoleCreate, RoleList } from "./pages/visa/role";
import { RoleEdit } from "./pages/visa/role/edit";
import { UserList, UserCreate, UserEdit } from "./pages/visa/user";
import { DashboardPage } from "./pages/dashboard";
import { accessControlProvider } from "./providers/accessControlProvider";
import { useEffect, useState } from "react";
import { useFullscreen } from "./contexts/full-screnn";
import { DoneAll } from "@mui/icons-material";



function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, options?: any) => {
      var response = t(key, options);
      if (typeof response === "string") return response;

      return key;
    },
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };




  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
              i18nProvider={i18nProvider}
              dataProvider={dataProvider(GetServiceUrl())}
              notificationProvider={useNotificationProvider}
              authProvider={authProvider}
              routerProvider={routerBindings}
              accessControlProvider={accessControlProvider}
              resources={[
                {
                  name: "dashboard",
                  list: "/",
                  meta: {
                    label: "Dashboard",
                    icon: <Dashboard />,
                  },
                },
                {
                  name: "Definition",
                  meta: {
                    label: t("pages.definition.label"),
                    icon: <AppRegistrationIcon />
                  }
                },
                {
                  name: "Visa",
                  meta: {
                    label: t("pages.visa.label"),
                    icon: <LockPersonIcon />
                  },
                },
                {
                  name: "User",
                  list: "/user",
                  create: "/user/create",
                  edit: "/user/edit/:id",
                  meta: {
                    label: t("pages.visa.user.label"),
                    parent: "Visa",
                    icon: <PersonIcon />
                  }
                },
                {
                  name: "Role",
                  list: "/role",
                  create: "/role/create",
                  edit: "/role/edit/:id",
                  meta: {
                    label: t("pages.visa.role.label"),
                    parent: "Visa",
                    icon: <FingerprintIcon />
                  }
                }
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                useNewQueryKeys: true,
                projectId: "T1ug9I-uCCfpg-NxMGRp",
                disableTelemetry: true
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated
                      key="authenticated-inner"
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <ThemedLayoutV2
                        Header={() => <ThemedHeaderV2 sticky />}
                        Title={({ collapsed }) => (
                          <ThemedTitleV2
                            collapsed={collapsed}
                            text="Bizimtoptan IYS Yönetim Sistemi"
                            icon={<AppIcon />}
                          />
                        )}
                        Sider={ThemedSiderV2}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route index element={<DashboardPage />} />

                  <Route path="/role">
                    <Route index element={<RoleList />} />
                    <Route path="create" element={<RoleCreate />} />
                    <Route path="edit/:id" element={<RoleEdit />} />
                  </Route>

                  <Route path="/user">
                    <Route index element={<UserList />} />
                    <Route path="create" element={<UserCreate />} />
                    <Route path="edit/:id" element={<UserEdit />} />
                  </Route>


                  <Route path="/forbidden" element={<ErrorComponent errorCode={403} />} />



                  <Route path="*" element={<ErrorComponent errorCode={404} />} />
                </Route>
                <Route
                  element={
                    <Authenticated
                      key="authenticated-outer"
                      fallback={<Outlet />}
                    >
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPassword />}
                  />
                </Route>
               
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
