import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

import "./internationalization/i18n";

import * as Sentry from "@sentry/react";
import { VersionProvider } from "./contexts/version-checker";
import { FullscreenProvider } from "./contexts/full-screnn";



/*
Sentry.init({
  dsn: "https://299c58a1a2cf6ef8aef5660dd2a92582@o4507379489374208.ingest.de.sentry.io/4507379494092880",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
*/


const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <VersionProvider>
        <FullscreenProvider>
          <App />
        </FullscreenProvider>
      </VersionProvider>
    </React.Suspense>
  </React.StrictMode>
);
