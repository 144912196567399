import { Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { PermissionModule, RolePermissions } from "./types";
import { useApiUrl, useCustom, useParsed, useShow } from "@refinedev/core";
import { GetServiceUrl } from "../../../../helpers/configManager";
import { IServiceResponse } from "../../../../model/IServiceResponse";
import { useEffect, useState } from "react";
import { IRoleResponse } from "../../../../model/visa/role/IRoleResponse";

interface ControllerResponse {
    moduleName: string;
}


export const Rbac = (
    { onChange,
        rows,
        initialRows }:
        {
            onChange: (activePerms: Array<string>) => void,
            rows: PermissionModule[],
            initialRows: RolePermissions
        }) => {


    const [checkedElements, setCheckedElements] = useState<Array<string>>(initialRows?.permissions?.map((perm: PermissionModule) => perm.controller + "." + perm.permissionType) ?? []);


    const IsChecked = (targetId: string) => {
        return checkedElements.indexOf(targetId) > -1;
        /*
        if (checkedElements.indexOf(targetId) > -1) return true;

        return IsDefaultChecked(targetId);
        */
    }

    const IsDefaultChecked = (moduleName: string): boolean => {
        var returnValue = ((initialRows?.permissions.filter((value: PermissionModule) => {
            if ((value.controller + "." + value.permissionType) == moduleName) {
                console.log(moduleName + " must be checked initially.")
            }


            return (value.controller + "." + value.permissionType) == moduleName;
        }))?.length ?? 0) > 0;

        return returnValue;
    }

    console.log("----rows------")
    console.log(rows)
    console.log("------------")

    console.log("----checkedElements------")
    console.log(checkedElements)
    console.log("------------")


    const { data, isLoading } = useCustom<IServiceResponse<Array<ControllerResponse>>>({
        url: `${GetServiceUrl()}/role/getControllers`,
        method: "get",
    });

    const handleChange = (module: string, checked: boolean) => {
        var currentPerms = checkedElements;

        if (!checked) {
            var newPerm = currentPerms.filter((value: string) => {
                return value != module;
            })

            setCheckedElements(newPerm);
            onChange(newPerm);
        }
        else {
            var wantedPerm = currentPerms.find((value: string) => {
                return value == module;
            })

            if (!wantedPerm) {
                var controller = module.split('.')[0];
                var permissionType = module.split('.')[1] as "List" | "Show" | "Create" | "Edit" | "Delete";

                currentPerms.push(controller + "." + permissionType);
                setCheckedElements(currentPerms);
                onChange(currentPerms);

            }
        }
    }

    return (
        <>
            <br />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="İzinler" >
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Modül</TableCell>
                            <TableCell align="center">List</TableCell>
                            <TableCell align="center">Show</TableCell>
                            <TableCell align="center">Create</TableCell>
                            <TableCell align="center">Edit</TableCell>
                            <TableCell align="center">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        isLoading ? <CircularProgress /> :
                            data?.data.data.map(
                                (row) => (
                                    <TableBody>
                                        <TableRow
                                            key={row.moduleName}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.moduleName}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox id={row.moduleName + ".List"}
                                                    checked={IsChecked(row.moduleName + ".List")}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChange(event.target.id, event.target.checked);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox id={row.moduleName + ".Show"}
                                                    checked={IsChecked(row.moduleName + ".Show")}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChange(event.target.id, event.target.checked);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox id={row.moduleName + ".Create"}
                                                    checked={IsChecked(row.moduleName + ".Create")}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChange(event.target.id, event.target.checked);
                                                    }} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox id={row.moduleName + ".Edit"}
                                                    checked={IsChecked(row.moduleName + ".Edit")}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChange(event.target.id, event.target.checked);
                                                    }} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox id={row.moduleName + ".Delete"}
                                                    checked={IsChecked(row.moduleName + ".Delete")}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChange(event.target.id, event.target.checked);
                                                    }} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>

                                )
                            )
                    }

                </Table>
            </TableContainer>
        </>

    );
}