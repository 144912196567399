function capitalizeWord(str: string): string {
    // String boş veya null ise, gelen string'i olduğu gibi döndür.
    if (!str) return str;

    // İlk harfi büyük yap ve geri kalan string ile birleştir.
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function capitalizeWords(str: string): string {
    return str.split(' ').map(word => capitalizeWord(word)).join(' ');
}

const getLastPartOfUrl = (url: string): string => {
    try {
        const parsedUrl = new URL(url);
        const pathParts = parsedUrl.pathname.split('/');
        return pathParts[pathParts.length - 1] || url;
    } catch (e) {
        console.error('Invalid URL', e);
        return url;
    }
}

export {
    capitalizeWord,
    capitalizeWords,
    getLastPartOfUrl
};