// arrayExtensions.ts
export {};


if (!Array.prototype.findLastIndex) {
    Array.prototype.findLastIndex = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): number {
        for (let i = this.length - 1; i >= 0; i--) {
            if (predicate(this[i], i, this)) {
                return i;
            }
        }
        return -1;
    };
}
