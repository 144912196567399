import { HttpError } from "@refinedev/core";
import { GetServiceUrl } from "../../../helpers/configManager";
import { StorageProxy, StorageType } from "./StorageProxy";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { refreshTokens, shouldRefreshToken } from "./refresh-token";
import { Token } from "axios-jwt/dist/src/Token";
import { IAuthTokens } from "axios-jwt";
import { getBrowserLocalStorage } from "./class/BrowserStorageType";
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const TENANT_KEY = `tenant-${process.env.NODE_ENV}`
const STORAGE_KEY = `auth-tokens-${process.env.NODE_ENV}`

const convertAxiosToFetchResponse = (response: AxiosResponse) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    response.headers["forEach"] = function (callback: any) {
        for (const header in this) {
            if (Object.hasOwn(this, header)) {
                callback(this[header], header, this);
            }
        }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    response["text"] = async function () {
        return JSON.stringify(this.data);
    };
    SetResponseOk(response, true);
};

const SetResponseOk = (response: AxiosResponse, ok: boolean) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    response["ok"] = ok;
};

// İstekleri bekletmek için bir kuyruk oluşturun
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// 1. Create an axios instance that you wish to apply the interceptor to
const axiosInstance = axios.create({
    baseURL: GetServiceUrl()
});


axiosInstance.interceptors.request.use(
    async (config) => {
        const accessToken = await getAccessToken();;
        if (accessToken && config?.headers) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);



axiosInstance.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (error) => {
        SetResponseOk(error, false);
        var messages = error.response?.data?.Messages;

        let customError: HttpError = {
            ...error,
            statusCode: error.response?.status,
        };

        if (messages && messages.length > 0) {
            if (messages.length == 1) {
                customError.message = messages[0];
            }
            else {
                customError.message = messages.join(' | ');
            }
        }
        else if (error.response?.data?.errors) {
            let validationMessages: string[] = [];

            Object.keys(error.response?.data?.errors).forEach((key: string) => {
                var propErrors = error.response?.data?.errors[key] as string[];
                if (propErrors)
                    validationMessages.push(...propErrors);
            });

            customError.message = validationMessages.join(' | ');
        }
        else if (error.response?.data?.Exception) {
            customError.message = error.response?.data?.Exception;
        }
        else {
            customError.message = "Some Error Occured.";
        }


        return Promise.reject(customError);
    },
);

createAuthRefreshInterceptor(axiosInstance, refreshTokens, {
    pauseInstanceWhileRefreshing: true
});

const applyStorage = (storage?: StorageType) => {
    if (storage) {
        StorageProxy.Storage = storage
    }
}

// New to 2.2.0+: initialize with storage: localStorage/sessionStorage/nativeStorage. Helpers: getBrowserLocalStorage, getBrowserSessionStorage
const getStorage = getBrowserLocalStorage;

applyStorage(getStorage());

const getTenant = async () => {
    return await StorageProxy.Storage?.get(TENANT_KEY) ?? "";
}

const setTenant = async (tenant: string) => {
    await StorageProxy.Storage?.set(TENANT_KEY, tenant)
}

const getAuthTokens = async (): Promise<IAuthTokens | undefined> => {
    const rawTokens = await StorageProxy.Storage?.get(STORAGE_KEY)
    if (!rawTokens) return

    try {
        // parse stored tokens JSON
        return JSON.parse(rawTokens)
    } catch (error: unknown) {
        if (error instanceof SyntaxError) {
            error.message = `Failed to parse auth tokens: ${rawTokens}`
            throw error
        }
    }
}

/**
 * Sets the access token
 * @param {Token} token - Access token
 */
export const setAccessToken = async (token: Token): Promise<void> => {
    const tokens = await getAuthTokens()
    if (!tokens) {
        throw new Error('Unable to update access token since there are no tokens currently stored')
    }

    tokens.accessToken = token
    await setAuthTokens(tokens)
}

/**
 * Returns the stored refresh token
 * @returns Refresh token
 */
export const getRefreshToken = async (): Promise<Token | undefined> => {
    const tokens = await getAuthTokens()
    return tokens ? tokens.refreshToken : undefined
}

/**
 * Returns the stored access token
 * @returns Access token
 */
export const getAccessToken = async (): Promise<Token | undefined> => {
    const tokens = await getAuthTokens()
    return tokens ? tokens.accessToken : undefined
}

/**
 * Clears both tokens
 */
export const clearAuthTokens = async (): Promise<void> =>
    await StorageProxy.Storage?.remove(STORAGE_KEY)

/**
 * Checks if refresh tokens are stored
 * @returns Whether the user is logged in or not
 */
export const isLoggedIn = async (): Promise<boolean> => {
    const token = await getRefreshToken()
    return !!token
}

axiosInstance.interceptors.request.use(async (request) => {
    request.headers['tenant'] = await getTenant();
    return request;
});

export const setAuthTokens = async (tokens: IAuthTokens): Promise<void> =>
    await StorageProxy.Storage?.set(STORAGE_KEY, JSON.stringify(tokens))

export { axiosInstance, getTenant, setTenant };