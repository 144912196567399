import { Box, CircularProgress, TextField } from "@mui/material";
import { useShow } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { Rbac } from "../../../components/pages/visa";
import { useState } from "react";
import { PermissionModule, RolePermissions } from "../../../components/pages/visa/permission/types";
import { FieldValues } from "react-hook-form";
import { Description } from "@mui/icons-material";
import { IRoleResponse } from "../../../model/visa/role/IRoleResponse";



export const RoleEdit = () => {
    const { queryResult } = useShow<IRoleResponse>({});
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const {
        saveButtonProps,
        register,
        formState: { errors },
        refineCore: { onFinish },
        handleSubmit
    } = useForm<any>(
        {
            defaultValues: {
                name: record?.name,
                description: record?.description,
            }
        }
    );


    const [currentClaims, setCurrentClaims] = useState<PermissionModule[]>(queryResult.data?.data.claims ?? []);

    const onFinishHandler = (data: FieldValues) => {
        onFinish({
            ...data,
            claims: currentClaims
        });
    };

    const onPermissionChange = (activePerms: Array<string>) => {
        var newPerms = activePerms.map((value: string) => {
            let perm: PermissionModule = {
                controller: value.split('.')[0],
                permissionType: value.split('.')[1] as 'List' | 'Show' | 'Create' | 'Edit' | 'Delete'
            }

            return perm;
        });

        setCurrentClaims(newPerms);
    }


    return (
        <Edit saveButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(onFinishHandler)
        }}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={"Name"}
                    name="name"
                />
                <TextField
                    {...register("description", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={"Description"}
                    name="description"
                />

                {isLoading ?
                    <CircularProgress /> : <Rbac
                        rows={currentClaims}
                        initialRows={{
                            permissions: queryResult.data?.data.claims ?? []
                        }}
                        onChange={onPermissionChange}
                    />}


            </Box>
        </Edit>
    );
};
