import { useForm } from "@refinedev/react-hook-form";
import * as React from "react";
import { FormProvider } from "react-hook-form";
import {
  LoginPageProps,
  LoginFormTypes,
  useActiveAuthProvider,
  BaseRecord,
  HttpError,
  useLogin,
  useTranslate,
  useRouterContext,
  useRouterType,
  useLink,
  useNavigation,
  useParsed,
} from "@refinedev/core";
import { ThemedTitleV2 } from "@refinedev/mui";
import { ExtendedLoginFormTypes, LoginFormPropsType } from "../index";
import { layoutStyles, titleStyles } from "./styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import type { BoxProps } from "@mui/material/Box";
import type { CardContentProps } from "@mui/material/CardContent";
import { useParams } from "react-router-dom";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { IServiceResponse } from "../../../../model/IServiceResponse";
import { axiosInstance } from "../../../../providers/data-provider/utils";
import { setAuthTokens } from "../../../../providers/data-provider/utils/axios";
import { Skeleton } from "@mui/material";

type LoginProps = LoginPageProps<BoxProps, CardContentProps, LoginFormPropsType>;



/**
 * login will be used as the default type of the <AuthPage> component. The login page will be used to log in to the system.
 * @see {@link https://refine.dev/docs/api-reference/mui/components/mui-auth-page/#login} for more details.
 */
export const LoginPage: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
  hideForm,
}) => {
  const { onSubmit, ...useFormProps } = formProps || {};
  const methods = useForm<BaseRecord, HttpError, ExtendedLoginFormTypes>({
    ...useFormProps,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = methods;

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<ExtendedLoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const { } = useParams();

  const {
    params: {
      accessToken, refreshToken // TParams - Any other parameters are also parsed and available in `params`
    },
  } = useParsed<any>();

  const [showCheckingPage, setShowCheckingPage] = React.useState(true);

  const { push } = useNavigation();

  React.useEffect(() => {
    if (accessToken && refreshToken) {

      const requestConfig: AxiosRequestConfig<any> = {
        skipAuthRefresh: true
      };

      axiosInstance.get<IServiceResponse<boolean>>("token/validate?token=" + accessToken, requestConfig).then(async (tokenValidateResponse) => {
        const isAuthenticated = tokenValidateResponse.data.data;

        if (isAuthenticated) {
          // save tokens to storage
          await setAuthTokens({
            accessToken,
            refreshToken
          });

        }
      }).finally(() => {
        setShowCheckingPage(false);
        push("/");
      });
    }
    else {
      setShowCheckingPage(false)
    }
  }, [accessToken, refreshToken]);


  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title ?? (
          <ThemedTitleV2
            collapsed={false}
            wrapperStyles={{
              gap: "8px",
            }}
          />
        )}
      </div>
    );


  const Content = (
    <>

      <Card {...(contentProps ?? {})}>
        {
          showCheckingPage ?
            <>
              <Skeleton variant="rectangular" title="test" width="100%" />
              <Typography
                alignContent="center"
                alignItems="center"
                alignSelf="center"
                justifyContent="center"
                justifyItems="center"
                justifySelf="center"
                style={{
                  ...titleStyles,
                  alignContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  justifySelf: "center"
                }}
                color="primary"
              >
                {translate("pages.login.autoLogin", "Otomatik Giriş Kontrolü Yapılıyor..")}
              </Typography>
            </> : <CardContent sx={{ p: "32px", "&:last-child": { pb: "32px" } }}>
              <Typography
                component="h1"
                variant="h5"
                align="center"
                style={titleStyles}
                color="primary"
                fontWeight={700}
              >
                {translate("pages.login.title", "Sign in to your account")}
              </Typography>

              {!hideForm && (
                <Box
                  component="form"
                  onSubmit={handleSubmit((data) => {
                    if (onSubmit) {
                      return onSubmit(data);

                    }
                    return login(data);
                  })}
                >
                  {
                    /*
                     <TextField
                      {...register("tenant", {
                        required: true,
                      })}
                      id="tenant"
                      margin="normal"
                      fullWidth
                      label={translate("pages.login.fields.tenant", "Tenant")}
                      error={!!errors.tenant}
                      name="tenant"
                      type="tenant"
                      autoComplete="tenant"
                      sx={{
                        mt: 0,
                      }}
                    />
                    */
                  }
                  <TextField
                    {...register("identifier")}
                    id="identifier"
                    margin="normal"
                    fullWidth
                    label={translate("pages.login.fields.identifier", "Identifier")}
                    error={errors.identifier ? true : false}
                    helperText={errors.identifier?.message}
                    name="identifier"
                    type="text"
                    autoComplete="identifier"
                    sx={{
                      mt: 0,
                    }}
                  />
                  <TextField
                    {...register("password", {
                      required: true,
                    })}
                    id="password"
                    margin="normal"
                    fullWidth
                    name="password"
                    label={translate("pages.login.fields.password", "Password")}
                    helperText={errors?.password?.message}
                    error={!!errors.password}
                    type="password"
                    placeholder="●●●●●●●●"
                    autoComplete="current-password"
                    sx={{
                      mb: 0,
                    }}
                  />
                  <Box
                    component="div"
                    sx={{
                      mt: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {rememberMe ?? (
                      <FormControlLabel
                        sx={{
                          span: {
                            fontSize: "14px",
                            color: "text.secondary",
                          },
                        }}
                        color="secondary"
                        control={
                          <Checkbox
                            size="small"
                            id="remember"
                            {...register("remember")}
                          />
                        }
                        label={translate(
                          "pages.login.buttons.rememberMe",
                          "Remember me"
                        )}
                      />
                    )}
                    {forgotPasswordLink ?? (
                      <MuiLink
                        variant="body2"
                        color="primary"
                        fontSize="12px"
                        component={ActiveLink}
                        underline="none"
                        to="/forgot-password"
                      >
                        {translate(
                          "pages.login.buttons.forgotPassword",
                          "Forgot password?"
                        )}
                      </MuiLink>
                    )}
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    sx={{ mt: "24px" }}
                  >
                    {translate("pages.login.signin", "Sign in")}
                  </Button>
                </Box>
              )}
            </CardContent>
        }



      </Card>
    </>

  );

  return (
    <FormProvider {...methods}>
      <Box component="div" style={layoutStyles} {...(wrapperProps ?? {})}>
        <Container
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: hideForm ? "flex-start" : "center",
            alignItems: "center",
            minHeight: "100dvh",
            padding: "16px",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
              paddingTop: hideForm ? "15dvh" : 0,
            }}
          >
            {renderContent ? (
              renderContent(Content, PageTitle)
            ) : (
              <>
                {PageTitle}
                {Content}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </FormProvider>
  );
};
