import React, { useContext, useState } from "react";
import {
  useGetIdentity,
  useActiveAuthProvider,
  pickNotDeprecated,
  useTranslation,
  useCustomMutation,
  useNotification,
  useInvalidate,
  useTranslate,
  useLogout,
  useWarnAboutChange,
  useIsExistAuthentication,
} from "@refinedev/core";
import { HamburgerMenu } from "./hamburgerMenu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import Logout from "@mui/icons-material/Logout";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import IconButton from "@mui/material/IconButton";

import { ColorModeContext } from "../../contexts/color-mode";
import { useTranslation as useI18nTranslation } from "react-i18next";
import { Divider, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GetServiceUrl } from "../../helpers/configManager";
import { t } from "i18next";
import { useVersion } from "../../contexts/version-checker";
import { useFullscreen } from "../../contexts/full-screnn";

export const ThemedHeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky,
  sticky,
}) => {
  const translate = useTranslate();
  const authProvider = useActiveAuthProvider();
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });


  const { warnWhen, setWarnWhen } = useWarnAboutChange();

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        t(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes."
        )
      );

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
      }
    } else {
      mutateLogout();
    }
  };

  const isExistAuthentication = useIsExistAuthentication();

  const logout = isExistAuthentication && (
    <Tooltip
      title={t("buttons.logout", "Logout")}
      placement="right"
      arrow
    >
      <MenuItem onClick={() => handleLogout()} key="logout-header-button">
        <ListItemIcon
          sx={{
            justifyContent: "center",
            minWidth: "24px",
            transition: "margin-right 0.3s",
            marginRight: "12px",
            color: "currentColor",
          }}
        >
          <Logout />
        </ListItemIcon>
        <ListItemText
          primary={translate("buttons.logout", "Logout")}
          primaryTypographyProps={{
            noWrap: true,
            fontSize: "14px",
          }}
        />
      </MenuItem>
    </Tooltip>
  );

  const prefferedSticky = pickNotDeprecated(sticky, isSticky) ?? true;

  const { mode, setMode } = useContext(ColorModeContext);

  const { i18n } = useI18nTranslation();

  const { getLocale, changeLocale } = useTranslation();
  const currentLocale = getLocale();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const openUser = Boolean(anchorElUser);
  const handleMenuUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorElUser(null);
  };


  const [updateRollsLoading, setUpdateRollsLoading] = useState(false);
  const { open: openNotification } = useNotification();
  var { mutate } = useCustomMutation();
  const invalidate = useInvalidate();

  const updateRolls = () => {
    setUpdateRollsLoading(true);
    mutate({
      url: `${GetServiceUrl()}/Roll/syncRolls`,
      method: 'post',
      values: {}
    }
      ,
      {
        onError: (error, variables, context) => {
          setUpdateRollsLoading(false);
          handleCloseUser();
        },
        onSuccess: (data, variables, context) => {
          setUpdateRollsLoading(false);
          handleCloseUser();

          openNotification?.({
            type: 'success',
            key: 'syncRolls',
            message: translate("notifications.success")
          });

          invalidate({
            resource: "Party",
            invalidates: ["list"],
          });

          invalidate({
            resource: "ExternalParty",
            invalidates: ["list"],
          });

          invalidate({
            resource: "Roll",
            invalidates: ["all"],
          });
        },
      })
  }

  
  const { enterFullscreen, exitFullscreen, toggleFullscreen, isFullScreen } = useFullscreen();
  const { currentVersion, latestVersion } = useVersion();

  return (
    <AppBar position={sticky ? "sticky" : "relative"}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          <HamburgerMenu />
          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              color="inherit"
              onClick={handleMenu}
            >
              <Avatar sx={{ width: 24, height: 24 }} src={`/images/flags/${currentLocale}.svg`} variant="circular" />
            </IconButton>

            <Menu
              id="lang-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              {[...(i18n.languages || [])].sort().map((lang: string) => (
                <MenuItem
                  onClick={() => changeLocale(lang)}
                  key={lang}
                >
                  <ListItemIcon>
                    <Avatar sx={{ width: 24, height: 24 }} src={`/images/flags/${lang}.svg`} alt={lang === "en-US" ? "English" : "Türkçe"} variant="circular" />
                  </ListItemIcon>
                  <ListItemText>{lang === "en-US" ? "English" : "Türkçe"}</ListItemText>

                </MenuItem>
              ))}
            </Menu>


            <IconButton
              color="inherit"
              onClick={() => {
                setMode();
              }}
            >
              {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>

            <IconButton
              color="inherit"
              onClick={() => {
                toggleFullscreen();
              }}
            >
              {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>


            {(user?.firstName) && (
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"
              >

                {user?.firstName && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <Typography
                      sx={{
                        display: {
                          xs: "none",
                          sm: "inline-block",
                        },
                        cursor: "pointer"
                      }}
                      variant="subtitle2"
                      onClick={handleMenuUser}
                    >
                      {user?.firstName}  {user?.lastName ?? ""}
                    </Typography>
                  </>
                )}

                <Menu
                  id="user-menu"
                  anchorEl={anchorElUser}
                  keepMounted
                  open={openUser}
                  onClose={handleCloseUser}
                >
                  <MenuItem disabled key="version-header-button">
                    <ListItemText
                      primary={"Version: " + currentVersion}
                      primaryTypographyProps={{
                        noWrap: true,
                        fontSize: "14px",
                      }}
                    />

                  </MenuItem>
                  <MenuItem onClick={() => updateRolls()} disabled={updateRollsLoading ? true : false} key="update-roll-header-button">
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                        minWidth: "24px",
                        transition: "margin-right 0.3s",
                        marginRight: "12px",
                        color: "currentColor",
                      }}
                    >
                      {updateRollsLoading ? <HourglassBottomIcon /> : <SyncAltIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={translate("buttons.updateRolls")}
                      primaryTypographyProps={{
                        noWrap: true,
                        fontSize: "14px",
                      }}
                    />
                  </MenuItem>



                  {logout}
                </Menu>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
