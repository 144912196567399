import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import Version from "../../../public/version.json";


type VersionContextType = {
    currentVersion: string;
    latestVersion?: string;
};


const VersionContext = createContext<VersionContextType>({} as VersionContextType);

export const VersionProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [currentVersion, setCurrentVersion] = useState(Version.version); // Uygulamanızın mevcut versiyonu
    const [latestVersion, setLatestVersion] = useState(undefined);

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const response = await fetch('/version.json', { cache: 'no-store' });
                const data = await response.json();
                setLatestVersion(data.version);
            } catch (error) {
                console.error('Versiyon kontrolü sırasında bir hata oluştu:', error);
            }
        };

        checkVersion();

        // 1 dakikada bir versiyon kontrolü
        const interval = setInterval(checkVersion, 1000 * 60 * 1);
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        if (latestVersion && latestVersion !== currentVersion) {
            if (window.confirm('Yeni bir versiyon mevcut(' + latestVersion + '). Sayfayı yeniden yüklemek ister misiniz? (Current: ' + currentVersion + ')')) {
                window.location.href = window.location.href; // Sayfayı yeniden yüklemenin bir yolu olarak kullanılıyor
            }
        }
    }, [latestVersion, currentVersion]);

    return (
        <VersionContext.Provider value={{ currentVersion, latestVersion }}>
            {children}
        </VersionContext.Provider>
    );
};

export const useVersion = () => {
    return useContext(VersionContext);
};