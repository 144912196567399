import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Paper, Tooltip } from '@mui/material';

type ValueCardProps = {
    title: string,
    children?: React.ReactNode;
    secondaryChildren?: React.ReactNode;
    href?: string;
    tooltipMessage?: string;
}



export const ValueCard = ({ title, children, secondaryChildren, href, tooltipMessage }: ValueCardProps) => {

    if (tooltipMessage) {
        return (
            <Tooltip title={tooltipMessage}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <React.Fragment>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            {title}
                        </Typography>
                        <Typography component="p" variant="h4">
                            {children}
                        </Typography>
                        <Typography color="text.secondary" sx={{ flex: 1 }}>
                            {secondaryChildren}
                        </Typography>
                        {href != undefined ? <Link color="primary" to={href}>
                            Detay
                        </Link> : <></>}

                    </React.Fragment>
                </Paper>
            </Tooltip>
        )
    }
    else {

        return (
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <React.Fragment>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>
                        {title}
                    </Typography>
                    <Typography component="p" variant="h4">
                        {children}
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                        {secondaryChildren}
                    </Typography>
                    {href != undefined ? <Link color="primary" to={href}>
                        Detay
                    </Link> : <></>}

                </React.Fragment>
            </Paper>
        );
    }
}
