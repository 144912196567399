import { BaseRecord, HttpError, useModal, useModalProps } from "@refinedev/core";
import { UseDataGridProps, useDataGrid } from "@refinedev/mui";
import { useEffect, useState } from "react";
import { isPermitted } from "../../providers/accessControlProvider";

export const useIsPermitted = (resource: string, action?: string) => {
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        const effectiveAction = action ?? "LIST";

        if (!hasPermission)
            isPermitted(resource, effectiveAction).then((result: boolean) => setHasPermission(result));
    }, [resource, action]);

    return hasPermission;
}