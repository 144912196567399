import { UseFormProps } from "@refinedev/react-hook-form";
import React from "react";
import { AuthPageProps, BaseRecord, ForgotPasswordFormTypes, HttpError, RegisterFormTypes, UpdatePasswordFormTypes } from "@refinedev/core";
import {
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  UpdatePasswordPage,
} from "./components";
import type { BoxProps } from "@mui/material/Box";
import type { CardProps } from "@mui/material/Card";

export interface ExtendedLoginFormTypes {
  tenant: string;
  identifier: string;
  password?: string;
  remember?: boolean;
  providerName?: string;
  redirectPath?: string;
}

export interface ExtendedForgotPasswordFormTypes {
  tenant: string;
  identifier: string;
}
export interface ExtendedRegisterFormTypes  {
  tenant: string;
  identifier?: string;
  password?: string;
  providerName?: string;
}
export interface ExtendedUpdatePasswordFormTypes  {
  tenant: string;
  password?: string;
  confirmPassword?: string;
}

export interface AuthPageFormTypes {
  tenant: string;
  identifier: string;
  password?: string;
  remember?: boolean;
  providerName?: string;
  redirectPath?: string;
  confirmPassword?: string;
}


export interface LoginFormPropsType extends UseFormProps<BaseRecord, HttpError, ExtendedLoginFormTypes> {
  onSubmit?: (values: ExtendedLoginFormTypes) => void;
}
export interface RegisterFormPropsType extends UseFormProps<BaseRecord, HttpError, ExtendedRegisterFormTypes> {
  onSubmit?: (values: ExtendedRegisterFormTypes) => void;
}
export interface UpdatePasswordFormPropsType extends UseFormProps<BaseRecord, HttpError, ExtendedUpdatePasswordFormTypes> {
  onSubmit?: (values: ExtendedUpdatePasswordFormTypes) => void;
}
export interface ForgotPasswordFormPropsType extends UseFormProps<BaseRecord, HttpError, ExtendedForgotPasswordFormTypes> {
  onSubmit?: (values: ExtendedForgotPasswordFormTypes) => void;
}

export type AuthProps = AuthPageProps<BoxProps, CardProps, any>;

/**
 * **refine** has a default auth page form served on the `/login` route when the `authProvider` configuration is provided.
 * @see {@link https://refine.dev/docs/api-reference/mui/components/mui-auth-page/} for more details.
 */
export const AuthPage: React.FC<AuthProps> = (props) => {
  const { type } = props;
  const renderView = () => {
    switch (type) {
      case "register":
        return <RegisterPage {...props} />;
      case "forgotPassword":
        return <ForgotPasswordPage {...props} />;
      case "updatePassword":
        return <UpdatePasswordPage {...props} />;
      default:
        return <LoginPage {...props} />;
    }
  };

  return <>{renderView()}</>;
};
