import { Autocomplete, Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useShow, useTranslation } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IRoleResponse } from "../../../model/visa/role/IRoleResponse";
import { Controller } from "react-hook-form";



export const UserEdit = () => {

  const {
    saveButtonProps,
    register,
    formState: { errors },
    control,
    setValue,
    getValues
  } = useForm();
  const { translate } = useTranslation();

  const { autocompleteProps } = useAutocomplete<IRoleResponse>({
    resource: "Role",
    defaultValue: getValues("roles")?.map((v: any) => v.id) || []
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("firstName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.firstName")}
          name="firstName"
        />
        <TextField
          {...register("lastName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.lastName")}
          name="lastName"
        />
        <TextField
          {...register("email", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.email")}
          name="email"
        />
        <TextField
          {...register("userName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.username")}
          name="userName"
        />

        <TextField
          {...register("password")}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.password")}
          name="password"
          required={false}
        />

        <TextField
          {...register("phoneNumber")}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.phoneNumber")}
          name="phoneNumber"
        />

        <FormControl>
          <Controller
            control={control}
            name="roles"
            defaultValue={[]}
            render={({ field }) => {
              const newValue = autocompleteProps.options.filter(
                (p) => field.value?.find((v: any) => v?.id === p?.id) !== undefined,
              );

              return (
                <Autocomplete
                  id="roles"
                  {...autocompleteProps}
                  {...field}
                  value={newValue}
                  multiple
                  clearOnBlur={false}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}

                  getOptionLabel={(item) => item.name}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    option?.id?.toString() === (value?.id ?? value)?.toString()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("pages.visa.user.fields.roles")}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              )
            }}
          />
        </FormControl>


        <FormControl>
          <FormLabel>{translate("pages.visa.user.fields.isActive")}</FormLabel>
          <Controller
            control={control}
            name="isActive"
            rules={{
              validate: (value) => {
                if (value === undefined) {
                  return translate("errors.required.field");
                }
                return true;
              },
            }}
            defaultValue={false}
            render={({ field }) => (
              <ToggleButtonGroup
                id="isActive"
                {...field}
                exclusive
                color="primary"
                onChange={(_, newValue) => {
                  setValue("isActive", newValue, {
                    shouldValidate: true,
                  });

                  return newValue;
                }}
              >
                <ToggleButton value={false} color="warning">
                  {translate("pages.visa.user.fields.isActiveFalse")}
                </ToggleButton>
                <ToggleButton value={true} color="success">
                  {translate("pages.visa.user.fields.isActiveTrue")}
                </ToggleButton>

              </ToggleButtonGroup>
            )}
          />
        </FormControl>

      </Box>
    </Edit>
  );
};
