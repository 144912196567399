import { CrudSorting } from "@refinedev/core";
import { capitalizeWord } from "../../../helpers/stringHelper";

export const generateSort = (sorters?: CrudSorting): string[] => {
  if (sorters && sorters.length > 0) {
    const _sort: string[] = [];

    sorters.map((item) => {
      let entity = capitalizeWord(item.field);
      if (item.order === "desc") {
        entity += " DESC";
      }

      _sort.push(entity);
    });

    return _sort;
  }

  return [];
};