import { Button, Card, CardActionArea, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useActiveAuthProvider, useCustom, useCustomMutation, useGetIdentity, useInvalidate, useList, useModal, useNavigation, useNotification, useTranslate } from "@refinedev/core";
import { useEffect, useRef, useState } from "react";
import { BarcodeFormat, Result } from '@zxing/library';
import { LoadingButton } from "@mui/lab";
import { GetServiceUrl } from "../../helpers/configManager";
import { getLastPartOfUrl } from "../../helpers/stringHelper";
import { useModalList } from "../../hooks";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { Image, QRCode } from 'antd';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { IRollResponse } from "../../model/fabric/IRollResponse";
import { isPermitted } from "../../providers/accessControlProvider";
import { useIsPermitted } from "../../hooks/use-is-permitted";
import { useDebounce } from "use-debounce";
import PrintTag, { PrintTagHandle } from "../../components/ticket/PrintTag";
import html2canvas from "html2canvas";
import { ValueCard } from "../../components/card";
import { IServiceResponse } from "../../model/IServiceResponse";
import { ISystemConfiguration } from "../../model/definition/ISystemConfiguration";
import { IUserResponse } from "../../model/visa/user/IUserResponse";
import { IRoleResponse } from "../../model/visa/role/IRoleResponse";
import { LineChart } from "@mui/x-charts";
import dayjs from "dayjs";

export const DashboardPage: React.FC = () => {
    const translate = useTranslate();
    const { show, close, visible } = useModal();
    const { open } = useNotification();
    const { show: navigateShow, list: navigateList } = useNavigation();
    var { mutate } = useCustomMutation();

    const hasPermission = useIsPermitted("ROLL");

    const [searchText, setSearchText] = useState('');
    const [debounceTime, setDebounceTime] = useState(3000); // Başlangıç debounce süresi
    const [filterValue] = useDebounce(searchText, debounceTime);
    const [remainingTime, setRemainingTime] = useState(0);
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    let lastSevenDays = []

    var now = dayjs()

    for (let i = 7; i > 0; i--) {
        lastSevenDays.push(now.add(i * -1, 'day'))
    }


    return (
        <>
            <Grid container columns={24} spacing={1}>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={6}>
                    <ValueCard
                        title={"Toplam Müşteri Sayısı"}
                        children={5893467}
                        secondaryChildren={"Adet Müşteri Mevcut."}
                        href="/definition/customers"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={6}>
                    <ValueCard
                        title={"Toplam Aktif Müşteri Sayısı"}
                        children={8321876}
                        secondaryChildren={"Adet Aktif Müşteri Mevcut."}
                        href="/definition/customers?filters[0][field]=isActive&filters[0][value]=true&filters[0][operator]=eq"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={6}>
                    <ValueCard
                        title={"KVKK İzinli Müşteri Sayısı"}
                        children={3078621}
                        secondaryChildren={"KVKK İzinli Müşteri Mevcut."}
                        href="/definition/customers?filters[0][field]=isActive&filters[0][value]=true&filters[0][operator]=eq&filters[1][field]=kvkkPermission&filters[1][value]=true&filters[1][operator]=eq"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={6}>
                    <ValueCard
                        title={"Dinleyici Sayısı"}
                        children={4}
                        secondaryChildren={"Adet Sisteme iys değişiklikleri yayınlanıyor."}
                        tooltipMessage="IYS sisteminden gelen değişiklikler ve/veya müşteri talebi doğrultusunda kasa,salesforce, vb. sistemlerden gelen herhangi bir değişikliği diğer sistemlere yaymak için dinleyici mantığı kullanılır. Bu sayede bir değişiklik olduğunda bu değişiklik tüm dinleyicilere gönderilerek sistemin güncel kalması sağlanır."
                        href="/definition/listeners"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}>
                    <ValueCard
                        title={"ETK Arama İzinli Müşteri Sayısı"}
                        children={1048621}
                        secondaryChildren={"Arama İzinli Müşteri Mevcut."}
                        href="/definition/customers?filters[0][field]=isActive&filters[0][value]=true&filters[0][operator]=eq&filters[1][field]=etkCallPermission&filters[1][value]=true&filters[1][operator]=eq"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}>
                    <ValueCard
                        title={"ETK Mesaj İzinli Müşteri Sayısı"}
                        children={148621}
                        secondaryChildren={"Mesaj İzinli Müşteri Mevcut."}
                        href="/definition/customers?filters[0][field]=isActive&filters[0][value]=true&filters[0][operator]=eq&filters[1][field]=etkMessagePermission&filters[1][value]=true&filters[1][operator]=eq"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}>
                    <ValueCard
                        title={"ETK E-Posta İzinli Müşteri Sayısı"}
                        children={874086}
                        secondaryChildren={"E-Posta İzinli Müşteri Mevcut."}
                        href="/definition/customers?filters[0][field]=isActive&filters[0][value]=true&filters[0][operator]=eq&filters[1][field]=etkMailPermission&filters[1][value]=true&filters[1][operator]=eq"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}>
                    <ValueCard
                        title={"UzmanCRM Servis Durumu"}
                        children={<Chip label="Bilinmiyor" color="warning" sx={{ width: '100%' }} />}
                        tooltipMessage="Son 10 dakikadır ilgili servise istek atılmadı"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}>
                    <ValueCard
                        title={"Sms Gateway Servis Durumu"}
                        children={<Chip label="Çalışıyor" color="success" sx={{ width: '100%' }} />}
                        tooltipMessage="Servis başarılı şekilde çalışıyor"
                    />
                </Grid>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}>
                    <ValueCard
                        title={"BTWS-CariIYSControl Servisi"}
                        children={<Chip label="Hata" color="error" sx={{ width: '100%' }} />}
                        tooltipMessage="Son 10 istekte hata alındı. Serviste hata olabilir."
                    />
                </Grid>
                <Grid item
                    xs={24}>
                    <ValueCard
                        title={"İzinli Müşteri Sayısı 1 Haftalık Değişim"}
                        children={
                            <LineChart
                                xAxis={[{ data: lastSevenDays, label: "Tarihler", valueFormatter: (value: any) => dayjs(value).format("DD/MM/YYYY") }]}
                                series={[
                                    {
                                        curve: "linear",
                                        data: [1200213, 1100213, 1500213, 1201253, 1621253, 1331253, 2001253],
                                        label: "KVKK Onay Değişimi"
                                    },
                                    {
                                        curve: "linear",
                                        data: [1300213, 1200213, 1200213, 1501253, 1671253, 1211253, 1861253],
                                        label: "ETK Arama Onay Değişimi"
                                    },
                                    {
                                        curve: "linear",
                                        data: [1400213, 1400213, 1300213, 1301253, 1591253, 1441253, 1931253],
                                        label: "ETK Mesaj Onay Değişimi"
                                    },
                                    {
                                        curve: "linear",
                                        data: [1500213, 1300213, 1400213, 1101253, 1441253, 1321253, 1291253],
                                        label: "ETK E-Posta Onay Değişimi"
                                    },
                                ]}
                                height={300}
                                grid={{ vertical: true, horizontal: true }}
                            />
                        }
                        secondaryChildren={"Haftalık İzin Değişimi"}
                    />
                </Grid>
            </Grid>
        </>
    );
}