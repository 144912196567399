import { AccessControlProvider, CanParams, ITreeMenu } from "@refinedev/core";
import { axiosInstance } from "./data-provider/utils";
import { authProvider } from "./authProvider";
import { isLoggedIn } from "./data-provider/utils/axios";
import { IUserResponse } from "../model/visa/user/IUserResponse";
import { IServiceResponse } from "../model/IServiceResponse";
import { CanResponse } from "@refinedev/core/dist/contexts/accessControl/types";
import { StorageProxy } from "./data-provider/utils/StorageProxy";
import { CacheManager } from "../helpers/cacheManager";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";


export const accessControlProvider: AccessControlProvider = {
    can: async ({ resource, action, params }: CanParams): Promise<CanResponse> => {
        var isAuthenticated = await isLoggedIn();

        if (isAuthenticated) {
            resource = resource?.toUpperCase();
            action = action.toUpperCase();

            var hasPermitted = await isPermitted(resource, action);

            if (hasPermitted) {
                return {
                    can: true,
                };
            }
            else {
                return {
                    can: false,
                    reason: "UnAuthenticated",
                };
            }
        }
        else {
            return {
                can: false,
                reason: "UnAuthenticated",
            };
        }
    },
    options: {
        buttons: {
            enableAccessControl: true,
            hideIfUnauthorized: true
        }
    }
}

export const getShowableMenus = async (treeMenu: ITreeMenu[]): Promise<string[]> => {
    const visibleMenus: string[] = [];

    for (const item of treeMenu) {
        const { name, children, identifier } = item;
        const isParentName = children.length > 0;

        if (isParentName) {
            var menus = await getShowableMenus(children);
            const canShow: boolean = menus.length > 0;

            if (canShow) {
                if (identifier)
                    visibleMenus.push(identifier.toUpperCase());
                else
                    visibleMenus.push(name.toUpperCase());

                visibleMenus.push(...menus);
            }
        }
        else {
            let canShow = false;

            if (identifier)
                canShow = await isPermitted(identifier.toUpperCase(), "LIST");
            else
                canShow = await isPermitted(name.toUpperCase(), "LIST");


            if (canShow) {
                if (identifier)
                    visibleMenus.push(identifier.toUpperCase());
                else
                    visibleMenus.push(name.toUpperCase());
            }
        }
    }

    return visibleMenus;
}

export const isPermitted = async (resource: string | undefined, action: string) => {
    if (resource == "DASHBOARD") resource = "ROLL";

    const isPermitted = await CacheManager.getItem<boolean>("permission_" + resource + "_" + action);

    if (isPermitted != undefined) return isPermitted;

    const requestConfig: AxiosRequestConfig<any> = {
        skipAuthRefresh: false
    };

    var response = await axiosInstance.get<IServiceResponse<boolean>>("user/00000000-0000-0000-0000-000000000000/hasPermission?permission=" + (resource?.toUpperCase() + "." + action.toUpperCase()), requestConfig);

    await CacheManager.setItem<boolean>("permission_" + resource + "_" + action, response.data.data);

    return response.data.data;
}
